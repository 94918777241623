@media(min-width:601px){
    .optionsouterrow{
        display: flex;
        flex-direction: row;
        background-color: #f8f2ee;
        height: 100vh;
    
        
    }
    .optionsoutercolumn{
        display: flex;
        flex-direction: column;
          margin-left: 20vw;
    
    }
    .optionsprofilerow{
        display: flex;
        flex-direction: row;
        margin-left: 10vw;
        margin-top: 10vh;
    }
    .optionsimg{
        height: 20vh;
    }
    .fullname{
        font-size: 4vw;
        margin-left: 2vw;
    }
    .optionsemail{
        margin-left: 2vw;
        font-size: 3vw;
        margin-top: 1vh;
    
    }
    
    
    .dataprivacy{
        font-size: 2.2vw;
        font-weight: bolder;
        margin-top: 6vh;
        margin-left: 10vw;
    
    }
    .dataprivacytext{
        font-size: 1.8vw;
        margin-left: 14vw;
    }
    .account{
        font-size: 2.2vw;
        font-weight: bolder;
        margin-top: 4vh;
        margin-left: 10vw;
    
    }
    .buttonrow{
        margin-top: 4vh; 
        margin-left: 14vw;
    }
    .switchuser{
        color:white;
        font-weight: bold;
        margin-right: 2vw;
    }
    .logout{
        width: 20vw;
        background-color: #c2353b;
        color:white;
        font-weight: bold;
        margin-right: 2vw;
    }
    .switchrow{
        display: flex;
        flex-direction: row;
        margin-top: 4vh; 
    }
    .wellnesstimertext{
        margin-top: 2vh; 
        font-size: 1.8vw;
        margin-left: 14vw;
    }
    .optionselect{
        margin-top: 4vh;
        height: 6vh;
        width: 15vw;
        font-size: 1.5vw;
        margin-left: 14vw;
        border-radius: 5px;
    }
}
@media(max-width:600px){
    .optionsouterrow{
        display: flex;
        flex-direction: row;
        background-color: #f8f2ee;
        height: 100vh;
    }
    .optionsoutercolumn{
        display: flex;
        flex-direction: column;
          /* margin-left: 20vw; */
    
    }
    .optionsprofilerow{
        display: flex;
        flex-direction: row;
        margin-left: 10vw;
        margin-top: 10vh;
    }
    .optionsimg{
        width: 20vw;
        /* height: 20vh; */
    }
    .fullname{
        font-size: 6vw;
        margin-left: 2vw;
    }
    .optionsemail{
        margin-left: 2vw;
        font-size: 4vw;
        margin-top: 1vh;
    }

    
    .dataprivacy{
        font-size: 3vw;
        font-weight: bolder;
        margin-top: 2vh;
        margin-left: 10vw;
    
    }
    .dataprivacytext{
        font-size: 2.5vw;
        margin-left: 14vw;
    }
    .account{
        font-size: 3vw;
        font-weight: bolder;
        margin-top: 2vh;
        margin-left: 10vw;
    
    }
    .buttonrow{
        margin-top: 2vh; 
        margin-left: 14vw;
    }
    .switchuser{
        color:white;
        font-weight: bold;
        margin-right: 2vw;
    }
    .logout{
        height: 5vh;
        width: 40vw;
        background-color: #c2353b;
        color:white;
        font-weight: bold;
        margin-right: 2vw;
    }
    .switchrow{
        display: flex;
        flex-direction: row;
        margin-top: 4vh; 
    }
    .wellnesstimertext{
        margin-top: 2vh; 
        font-size: 2.5vw;
        margin-left: 14vw;
    }
    .optionselect{
        margin-top: 2vh;
        height: 4vh;
        width: 20vw;
        font-size: 2vw;
        margin-left: 14vw;
        border-radius: 5px;
    }
}
