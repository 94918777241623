@media(min-width:601px){
  .whatdoisee {
    height: 45vh;
  }
  .whatdoihear {
    height: 45vh;
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
    background-color: #dcd9d6;
    /* justify-content: center; */
    margin-left: 15vw;
    height: 100vh;
  }
  .outer1 {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .seeandhearrow {
    /* margin-bottom: 24vh; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .seeandheartextrow{
    color: #4da95a;
    display: flex;
    flex-direction: row;
    font-size: 2.2vw;
    margin-bottom: 4vh;
    margin-top: 10vh;
    font-weight: bold;
    justify-items: center;
  }
  .see{
    margin-top: 0.75vh;
  }
  .seetext{
    margin-top: 0.75vh;
    color:  #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #5b9cd6;
  }
  .heartext{
    margin-top: 0.75vh;
    color:  #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #4cc68d;
  }
  .focusaway{
    font-size: 1.8vw;
    margin-top: 2vh;
    font-weight: bold;
  }
  .greennumberseeandhear{
    width: 2.5vw;
    height: fit-content;
  }
  .timerbar{
    width:40%;
    margin-top: 5vh;
  }
}
@media(max-width:600px){
  .timerbar{
    width:60vw;
    margin-top: 5vh;
    margin-left: 5vw;
    /* align-self: center; */
  }
  .whatdoisee {
    height: 30vh;
  }
  .whatdoihear {
    height: 30vh;
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #dcd9d6;
    /* justify-content: center; */
    /* margin-left: 15vw; */
    height: 80vh;
  }
  .outer1 {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .seeandhearrow {
    /* margin-bottom: 24vh; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .seeandheartextrow{
    color: #4da95a;
    display: flex;
    flex-direction: row;
    font-size: 4vw;
    margin-top: 10vh;
    font-weight: bold;
    justify-items: center;
  }
  .see{
    margin-top: 1.5vh;
  }
  .seetext{
    margin-top: 1.5vh;
    color:  #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #5b9cd6;
  }
  .heartext{
    margin-top: 1.5vh;
    color:  #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #4cc68d;
  }
  .focusaway{
    font-size: 3vw;
    margin-top: 2vh;
    font-weight: bold;
  }
  .greennumberseeandhear{
    width: 5vw;
    height: 6vh;
  }
}
