#emailLoginButton {
    background-color: #BD91E4;
    width: 20vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    padding: 5px;
}

#googleLoginButton {
    background-color: white;
    width: 20vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #767676;
    padding: 5px
}

#outerEmailBackground {
    background-color: white; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: flex-start;
    width: 30vw;
    margin-top: 2vh;
    border-radius: 20px;
    padding: 20px;
}

.emailLoginInputs {
    width: 90%;
    border: solid;
    border-width: 1px;
    border-color: #767676;
    padding: 10px;
    border-radius: 10px;
}

.labelText {
    margin-bottom: 10px;
}

#forgotPass:hover {
    cursor: pointer;
}

#emailImage {
    height: 45%;
    width: auto;
    margin-right: 5%; 
}

#googleImage {
    height: 45%;
    width: auto; 
    margin-right: 5%;
}

#cancelButton {
    width: 50%; 
    padding: 10px;
    border-width: '1px';
    height: 5vh;
    border-color: white;
}

#signInButton {
    width: 50%;
    background-color: #5A9BD6;
    color: white; 
    padding: 10px; 
    border-width: '1px';
    border-color: white;
    height: 5vh;
}

#microsoftButton {
    background-color: white;
    width: 20vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #767676;
    padding: 5px
}

#microLogoImg {
    height: 45%;
    width: auto; 
    margin-right: 5%;
}

@media (max-width: 600px) {
    #emailLoginButton {
        background-color: #BD91E4;
        width: 50vw;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 400;
        padding: 5px;
        font-size: 3.5vw;
    }
    
    #googleLoginButton {
        background-color: white;
        width: 50vw;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        color: #767676;
        padding: 5px;
        font-size: 3.5vw;
    }

    #microsoftButton {
        background-color: white;
        width: 50vw;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        color: #767676;
        padding: 5px;
        font-size: 3.5vw;
    }

    #emailImage {
        height: 25%;
    }

    #googleImage {
        height: 35%;
    }

    #microLogoImg {
        height: 25%;
    }

    #signInButton {
        width: 50%;
        background-color: #5A9BD6;
        color: white; 
        padding: 10px; 
        border-width: '1px';
        font-size: 2.8vw;
    }

    #cancelButton {
        width: 50%; 
        padding: 10px;
        border-width: '1px';
        font-size: 2.8vw;
        background-color: lightgray;
        color: black;
    }

    #outerEmailBackground {
        width: 70vw;
    }

}