@media (min-width: 601px) {
  .outerbackground {
    height: 135vh;
    background-color: #f8f2ee;
  }
  .columndatatrends {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    margin-left: 20vw;
  }
  .emotionsidentified {
    margin-top: 2vh;
    font-size: 1.2vw;
  }
  .datatrendstitle {
    margin-top: 5vh;
    font-size: 2.5vw;
    font-weight: bold;
  }
  .practicedwellness {
    margin-top: 3vh;
    /* margin-left: 8vw; */
    font-size: 2vw;
    text-align: center;
    width: 63vw;
  }
  .bargraphdatatrends {
    background-color: white;
    width: 63vw;
    height: 65vh;
    /* margin-left: 8vw; */
    margin-top: 2vh;
    justify-content: center;
    align-content: center;
  }
  .labelrow {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 0.8vh;
    font-size: 1.1vw;
  }
  .classrow {
    margin-left: 2.5vw;
  }
  .currentrow {
    margin-left: 11.5vw;
  }
  .previousrow {
    margin-left: 8vw;
  }
  .filterprofiledata {
    margin-top: 0vh;
    margin-left: 2vmax;
    height: 3vmax;
    width: 12vmax;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1vmax;
  }
  .changetimefilterdata {
    margin-top: 0vh;
    margin-left: 2vmax;
    height: 3vmax;
    width: 12vmax;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1vmax;
  }
  .datatrendsrow {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    font-size: 1.3vw;
  }
  .datatrendscolumn1 {
    /* display: flex; */
    /* flex-direction: column; */
    font-weight: bold;
    flex-wrap: wrap;
    width: 18vw;
    text-align: center;
    margin-right: 1vw;
    /* margin-top: 1vh; */
  }
  .datatrendscolumn2 {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
  }
  .datatrendsempty {
    margin-top: 7vh;
    font-size: 1.3vw;
  }
  .datatrendsbarchart{
    margin-left: 3vw;
    margin-top: 4vh;
    font-size: 1vw;
  }
  .greenlettersdata{
    color:#4cc68d;
    font-weight: bold;
  }
}

@media (max-width: 600px) {
  .outerbackground {
    height: 100%;
    background-color: #f8f2ee;
  }
  .columndatatrends {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    /* margin-left: 20vw; */
  }
  .emotionsidentified {
    margin-top: 2vh;
    font-size: 2.5vw;
  }
  /* .datatrendstitle {
    margin-top: 5vh;
    font-size: 4vw;
    font-weight: bold;
  } */
  .practicedwellness {
    margin-top: 3vh;
    font-size: 3.5vw;
    width:80vw;
    font-weight: bold;
    text-align: center;
  }
  .bargraphdatatrends {
    background-color: white;
    width: 90vw;
    height: 45vh;
    /* margin-left: 8vw; */
    margin-top: 2vh;
    justify-content: center;
    align-content: center;
  }
  .labelrow {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 0.8vh;
    font-size: 2.5vw;
  }
  .classrow {
    margin-left: 3vw;
  }
  .currentrow {
    margin-left: 18vw;
  }
  .previousrow {
    margin-left: 15vw;
  }
  .filterprofiledata {
    /* margin-top: 0vh; */
    margin-left: 2vw;
    height: 4vh;
    width: 20vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 2.5vw;
  }
  .changetimefilterdata {
    margin-top: 0vh;
    margin-left: 2vmax;
    height: 4vh;
    width: 26vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 2.5vw;
  }
  .datatrendsrow {
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
    font-size: 2.2vw;
  }
  .datatrendscolumn1 {
    font-weight: bold;
    flex-wrap: wrap;
    width: 30vw;
    text-align: center;
    margin-right: 1vw;
    margin-top: 1vh;

    /* margin-top: 1vh; */
  }
  .datatrendscolumn2 {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    width: 55vw;
    margin-left: 1vw;
    /* margin-top: 0.5vh; */

  }
  .datatrendsempty {
    width:80vw;
    margin-top: 5vh;
    font-size: 3vw;
    text-align: center;
  }

  .datatrendsbarchart{
    margin-left: 3vw;
    margin-top: 3vh;
    font-size: 2vw;
  }
  .greenlettersdata{
    color:#4cc68d;
    font-weight: bold;
  }
}
.labelindent{
  margin-left: 1vw;
  color: #3366CC;
}