.changetimefilter{
        margin-top: 2vh;
        margin-left: 2vw;
        height: 3vmax;
        width: 14vw;
        border-color: #d6d4cf;
        border-radius: 5px 5px 5px 5px;
        text-align: center;
        border-width: 0.1vmax;
        color: #78746d;
        font-size: 1vmax;
}
.demo-customized-button-class{
    margin-top: 2vh;
    background-color: white;
    margin-left: 2vw;
    box-shadow: none;
    height: 3vmax;
    width: 14vw;
    border-radius: 5px 5px 5px 5px;
    border-width: 0.1vmax;
    border-color: #d6d4cf;
    color: #78746d;
    font-size: 1vmax;
    text-align: center;
    border-style:solid;
}

.emojiDropdown {
    display: flex;
    align-items: center;
}