@media (min-width: 601px) {
.sidebar{
    border-radius: 0px 25px 25px 0px;
    display:flex;
    flex-direction:column;
    width:20vw;
    align-items: center;
    height:100vh;
    background-color:#4cc68d;
    position:fixed;
    /* justify-items: end; */

}
.styleiconstudenthome,.styleiconteacherhome,.styleiconadminhome,.styleiconwellnesshome{
    margin-top: 2vh;
    margin-left: 1vw;
    /* margin-top: 4vh; */
    font-size: 3vw;
    color:white;
    margin-right: 1vw;
}
.styleiconadmin,.styleiconstudent,.styleiconteacher,.styleiconwellness{
    margin-left: 1vw;
    margin-top: 2vh;
    font-size: 3vw;
    color:white;
    margin-right: 1vw;
}
.icontext{
    font-size: 1.2vw;
    margin-top: 4vh;
}
.icontexthome{
    margin-top: 4vh;
    font-size: 1.2vw;
    /* margin-top: 2vh; */
}
.icontextrowstudent{
    margin-bottom: 10vh;
    display:flex;
    flex-direction:row;
    color:white;
    justify-content: center;
}
.icontextrowteacher{
    margin-bottom: 4vh;
    display:flex;
    flex-direction:row;
    color:white;
    justify-content: center;
}
.icontextrowadmin{
    margin-bottom: 2vh;
    display:flex;
    flex-direction:row;
    color:white;
    justify-content: center;
}
.icontextrowwellness{
    /* margin-bottom: 0.5vh; */
    display:flex;
    flex-direction:row;
    color:white;
    justify-content: center;
}
.sidebarlogo{
    height:8vw;
}
.patentpending{
    margin-top: 2vh;
    font-size: 1vw;
    color:white;
}
.growthwell{
    font-size: 1vw;
    color:white;
}
.sidebarcolumn{
    display:flex;
    flex-direction:column;
    align-items: center;
}
.schoolanddistrict{
    text-align: center;
    color: white;
    margin-bottom: 1vh;
    font-size: 1.2vw;
    font-weight: bold;
}

.alignMenuOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 35px;
}
}
@media (max-width: 600px) {
    .sidebarcolumn{
        display:flex;
        flex-direction:column;
        align-items: center;
    }
    .sidebarrow{
        display:flex;
        flex-direction:row;
    }
    .sidebar{
        border-radius: 25px 25px 0px 0px;
        display:flex;
        flex-direction:row;
        width:100vw;
        height:15vh;
        background-color:#4cc68d;
        position:fixed;
        bottom: 0%;
    }
    .styleiconwellnesshome,.styleiconwellness{
        margin-left: 4vw;
        margin-top: 2vh;
        font-size: 7vw;
        color:white;
        margin-right: 6vw;
    }
    .styleiconadmin,.styleiconadminhome{
        margin-left: 7vw;
        margin-top: 2vh;
        font-size: 7vw;
        color:white;
        margin-right: 5vw;
    }
    .styleiconteacher,.styleiconteacherhome{
        margin-left: 10vw;
        margin-top: 1.5vh;
        font-size: 8vw;
        color:white;
        margin-right: 7vw;
    }
    .styleiconstudent,.styleiconstudenthome{
        margin-left: 13vw;
        margin-top: 1.5vh;
        font-size: 8vw;
        color:white;
        margin-right: 10vw;
    }
    .icontext,.icontexthome{
        font-size: 2vw;
        margin-top: 6vh;
        font-size: 0vw;
        height:0vh;
    }
    .icontextrowteacher,.icontextrowstudent, .icontextrowadmin, .icontextrowwellness{
        margin-top: 2vh;
        display:flex;
        flex-direction:row;
        color:white;
        justify-content: center;
    }
    .sidebarlogo{
        height:0vw;
    }
    .patentpending{
    margin-top: 1vh;
    font-size: 2.5vw;
    color:white;
}
.growthwell{
    font-size: 2.5vw;
    color:white;

}
.schoolanddistrict{
    font-size: 0vw;
    height: 0vh;
    width:0vh
}
.alignMenuOptions {
    display: flex;
}
    }
    