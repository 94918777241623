
@media(min-width:601px){
    .outercongrats{
        display:flex;
        flex-direction:row;
        background-color: #F8F2EE;
        height: 100vh;
    }
    .columncongrats{
        display:flex;
        flex-direction:column;
        width: 80vw;
        align-items: center;
        margin-left: 20vw;
    }
    .wasthishelpful{
        margin-top: 1vh;
        font-size: 1.8vw;
        font-weight: bold;
    }
    .rowcongrats{
        display:flex;
        flex-direction:row;
        margin-top: 2vh;
    }
    .helpfulyes{
        background-color:#4cc68d;
        width: 15vw;
        margin-right: 2vw;
        height: 5vh;
        border-radius: 15px;
    }
    .helpfulno{
        background-color: #bd92e4;
        width: 15vw;
        margin-left: 2vw;
        height: 5vh;
        border-radius: 15px;
    }
    .congratulationscastle{
        height: 75vh;
        width:55vw;
        margin-top: 3vh;
        border: 3px solid black;
    }
}


@media(max-width:600px){
    .outercongrats{
        display:flex;
        flex-direction:row;
        background-color: #F8F2EE;
        height: 100vh;
    }
    .columncongrats{
        display:flex;
        flex-direction:column;
        width: 100vw;
        align-items: center;
        /* margin-left: 20vw; */
    }
    .wasthishelpful{
        margin-top: 1vh;
        font-size: 3vw;
        font-weight: bold;
    }
    .rowcongrats{
        display:flex;
        flex-direction:row;
        /* margin-top: 2vh; */
    }
    .helpfulyes{
        background-color:#4cc68d;
        width: 30vw;
        height:5vh;
        margin-right: 2vw;
    }
    .helpfulno{
        background-color: #bd92e4;
        width: 30vw;
        height:5vh;
        margin-left: 2vw;
    }
    .congratulationscastle{
        /* height: 55vh; */
        width:90vw;
        margin-top: 6vh;
        border: 3px solid black;
    }
}
