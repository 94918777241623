@media(min-width:601px){
.classandactions {
  display: flex;
  flex-direction: column;
  width: 85vw;
  align-items: center;
  margin-left: 15vw;
  height: fit-content
  /* justify-content: center; */
}
.anythingineed {
  font-size: 2.2vw;
  margin-top: 15vh;
  font-weight: bold;
}
.greenrow {
  /* margin-top: 4vh; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #4cc68d; */
  width: 70vw;
  height: fit-content;
}
.bluerow {
  /* margin-top: 4vh; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #4cc68d; */
  width: 70vw;
  height: fit-content;
}
.purplerow {
  /* margin-top: 4vh; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #4cc68d; */
  width: 70vw;
  height: fit-content;
}
.actions {
  height: 30vh;
  width:fit-content;
  max-width:30vh;
  cursor:pointer;
}
.outeractions{
  background-color: #dcd9d6;
  display:flex;
  flex-direction: row;
}
.emotionscolumn{
  display:flex;
  flex-direction: column;
}
.imgTextBoxRow{
  display:flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
}
.submitaction{
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  background-color: #4cc68d;
}
.othertextbox{
  height: 2.5vh;
  width: 12vw;
}
}
@media(max-width:600px){
  .classandactions {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    align-items: center;
    /* margin-left: 15vw; */
    /* height: fit-content */
    /* justify-content: center; */
  }
  .anythingineed {
    font-size: 4vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .greenrow {
    /* margin-top: 4vh; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #4cc68d; */
    width: 90vw;
    height: fit-content;
  }
  .bluerow {
    /* margin-top: 4vh; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #4cc68d; */
    width: 90vw;
    height: fit-content;
  }
  .purplerow {
    /* margin-top: 4vh; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #4cc68d; */
    width: 90vw;
    height: fit-content;
  }
  .actions {
    height: 20vh;
    /* width:fit-content */
  }
  .outeractions{
    background-color: #dcd9d6;
    display:flex;
    flex-direction: row;
  }
  .emotionscolumn{
    display:flex;
    flex-direction: column;
  }
  .imgTextBoxRow{
    display:flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
  .submitaction{
    width: 20vw;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: white;
    background-color: #4cc68d;
  }
  .othertextbox{
    height: 2.5vh;
    width: 40vw;
  }
  }
  