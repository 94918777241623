@media (min-width: 601px) {
  .studentscol {
    flex-direction: column;
    display: flex;
    margin-left: 20vw;
    align-items: center;
  }
  .studentsheader {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 6vh;
  }
  .limitspreadsheet {
    margin-top: 6vh;
    height: 25vh;
    overflow-y: scroll;
  }
  .addstudents {
    margin-top: 10vh;
    background-color: #bd92e4;
    margin-left: 2vw;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
  }
  .seeclassdata {
    margin-top: 6vh;
    background-color: #5b9cd6;
    border-radius: 25px;
    /* border-color: white; */
    /* margin-left: 2vw; */
    height: 7vh;
    width: 20vw;
    font-size: 1.2vw
  }
  .studentsbuttonrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .editclassesrow {
    align-self: baseline;
    margin-left: 5vw;
    margin-top: 4vh;
  }
  .chooseaclasstext {
    font-size: 2vw;
    font-weight: bold;
  }
  .classselect {
    /* align-items: center; */
    margin-top: 2vh;
    /* margin-left: 8vw; */
    height: 6vh;
    width: 15vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1.2vw;
  }
  .editclassesrow {
    display: flex;
    flex-direction: row;
  }
  .addnewclass {
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
  }
  .newclasstextbox {
    margin-left: 4vw;
    height: 4vh;
    width: 14vw;
    /* margin-top: 7.2vh; */
    font-size: 1vw;
  }
  .addnewteachertextbox {
    margin-left: 4vw;
    height: 3vh;
    width: 14vw;
    margin-top: 1vh;
    font-size: 1vw;
  }
  .inputandbuttonrow {
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
  }
  .classselectdelete {
    margin-left: 4vw;
    margin-top: 1vh;
    height: 4.5vh;
    width: 14.5vw;
    color: #78746d;
    font-size: 1vw;
  }
  .spreadsheetrow {
    display: flex;
    flex-direction: row;
  }
  .removeastudentselect{
    /* margin-left: 4vw; */
    margin-top: 2vh;
    height: 4.5vh;
    width: 50.5vw;
    color: #78746d;
    font-size: 1vw;
  }
  .removeastudentbutton{
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 2vh;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
  }
  .selectstudentsrow{
    margin-top:5vh;
    display: flex;
    flex-direction: row;
  }
  .chooseclasstext{
    width:40vw;
    font-size: 2vw;
    font-weight: bold;
    margin-top: 5vh;
  }
}
@media (max-width: 600px) {
  .studentscol {
    flex-direction: column;
    display: flex;
    /* margin-left: 20vw; */
    align-items: center;
  }
  .studentsheader {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 4vh;
  }
  .limitspreadsheet {
    margin-top: 4vh;
    height: 15vh;
    font-size: 2.2vw;
    overflow-y: scroll;
  }
  .addstudents {
    margin-top: 5vh;
    background-color: #bd92e4;
    /* border-radius: 22px; */
    margin-left: 4vw;
    width: 20vw;
    height: 4vh;
    border-radius: 15px;
  }
  .seeclassdata {
    margin-top: 2vh;
    background-color: #5b9cd6;
    border-radius: 25px;
    /* border-color: white; */
    /* margin-left: 2vw; */
    height: 5vh;
    width: 20vw;
    font-size: 1.2vw
  }
  .studentsbuttonrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .editclassesrow {
    align-self: baseline;
    margin-left: 5vw;
    margin-top: 4vh;
  }
  .chooseaclasstext {
    font-size: 2.5vw;
    font-weight: bold;
  }
  .classselect {
    margin-top: 2vh;
    height: 5vh;
    width: 30vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 2.5vw;
  }
  .editclassesrow {
    display: flex;
    flex-direction: row;
  }
  .addnewclass {
    
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 2.2vw;
    height: 4vh;
    width: 18vw;
    border-radius: 15px;
  }
  .newclasstextbox {
    /* margin-left: 4vw; */
    height: 4vh;
    width: 20vw;
    /* margin-top: 7.2vh; */
    font-size: 2.2vw;
  }
  .addnewteachertextbox {
    /* margin-left: 4vw; */
    height: 4vh;
    width: 20vw;
    margin-top: 1vh;
    font-size: 2.2vw;
    
  }
  .inputandbuttonrow {
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
  }
  .classselectdelete {
    /* align-items: center; */
    margin-left: 4vw;
    margin-top: 1vh;
    /* margin-left: 8vw; */
    height: 4vh;
    width: 25vw;
    /* border-color: #d6d4cf; */
    border-radius: 5px 5px 5px 5px;
    /* text-align: center; */
    /* border-width: 0.1vmax; */
    color: #78746d;
    font-size: 2.2vw;
  }
  .spreadsheetrow {
    display: flex;
    flex-direction: row;
  }
  .removeastudentselect{
    /* margin-left: 4vw; */
    border-radius: 5px 5px 5px 5px;
    margin-top: 2vh;
    height: 5vh;
    width: 51vw;
    color: #78746d;
    font-size: 2.2vw;
  }
  .removeastudentbutton{
    margin-top: 2vh;
    background-color: #bd92e4;
    margin-left: 4vw;
    width: 20vw;
    height: 4vh;
    border-radius: 15px;
  }
  .selectstudentsrow{
    /* margin-top:5vh; */
    display: flex;
    flex-direction: row;
  }
  .chooseclasstext{
    font-size: 3vw;
    font-weight: bold;
    margin-top: 4vh;
    width: 50vw;
  }
}
