@media (min-width: 601px) {
  .emotionstired {
    width: 5.5vw;
    margin-top: 2.2vh;
    margin-right: 2vw;
  }
  .emotiontextcalm {
    margin-top: 0.8vh;
    font-size: 1.3vw;
    font-weight: bold;
    text-align: center;
  }
  .emotions {
    width: 5.5vw;
    margin-top: 3vh;
    margin-right: 2vw;
  }
  .innerfeelcol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .innerfeelrow {
    margin-right: 3vw;
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
  }
  .outerfeel {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .howdoifeelrow {
    display: flex;
    flex-direction: row;
    margin-left: 6vw;
    margin-top: 8vh;
  }
  .greennumber {
    margin-top: 1.7vh;
    width: 2.5vw;
    height: fit-content;
  }
  .howdoifeel {
    color: #4da95a;
    /* margin-left: 0.25vw; */
    font-size: 2.2vw;
    font-weight: bold;
  }
  .organizeheader {
    width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12vw;
    height: 100vh;
  }
  .emotiontext {
    margin-top: 0.5vh;
    font-size: 1.3vw;
    font-weight: bold;
    text-align: center;
  }
  .textandimgemotion {
    width: 5.5vw;
    margin-left: 6vw;
    margin-top: 3vh;
  }
  .reactplayer{
    display: none;
  }
}
@media (max-width: 600px) {
  .emotionstired {
    width: 10vw;
    margin-top: 2.2vh;
    margin-right: 2vw;
  }
  .emotiontextcalm {
    width:10vw;
    margin-top: 0.4vh;
    font-size: 2.5vw;
    font-weight: bold;
    text-align: center;
  }
  .emotions {
    width: 10vw;
    margin-top: 3vh;
    margin-right: 2vw;
  }
  .innerfeelcol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .innerfeelrow {
    margin-right: 3vw;
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
  }
  .outerfeel {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .howdoifeelrow {
    display: flex;
    flex-direction: row;
    /* margin-left: 6vw; */
    margin-top: 8vh;
  }
  .greennumber {
    width: 5vw;
    height: 6vh;
  }
  .howdoifeel {
    color: #4da95a;
    font-size: 4vw;
    font-weight: bold;
  }
  .organizeheader {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 12vw; */
    height: 100vh;
  }
  .emotiontext {
    width:10vw;
    margin-top: 0.5vh;
    font-size: 2.5vw;
    font-weight: bold;
    text-align: center;
  }
  .textandimgemotion {
    width: 8vw;
    margin-left: 6vw;
    margin-right: 6vw;
    margin-top: 3vh;
  }
  .reactplayer{
    display: none;
  }
}
