@media(min-width:601px){
  .text {
    font-size: 2.2vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .outercolumnbreathe{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
    margin-left: 15vw;
    height: 100vh;
}
.gif {
  height: 30vmax;
  margin-top: 5vh;
}
}
@media(max-width:600px){
  .text {
    font-size: 4vw;
    margin-top: 10vh;
    font-weight: bold;
  }
  .outercolumnbreathe{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.gif {
  height: 40vh;
  margin-top: 5vh;
}
}
.outerbreath1{
  background-color: #dcd9d6;
  display: flex;
  flex-direction: row;
}

