@media (min-width: 601px) {
  .nosignin {
    color: white;
    font-size: 1.8vw;
    font-weight: bold;
    width: 80vw;
  }
}
@media (max-width: 600px) {
  .nosignin {
    width: 80vw;
    color: white;
    font-size: 5vw;
    font-weight: bold;
    margin-top: 4vh;
  }
}
