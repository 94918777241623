@media (min-width: 601px) {

.outermostrow{
    background-color: #f8f2ee;
    width: 100vw;
    height: 100vh;
}
.castlebigger{
    margin-top: 1vh;
    width: 45vw;
    height:70vh;
    border: 3px solid black;

}
.castle{
    width: 40vw;
    height:60vh;
    border: 3px solid black;
    margin-top: 2vh;
}
.littlecastlerow{
    margin-top: 1vh;
    margin-left: 5vw;
    margin-right: 4vw;
    margin-bottom: 8vh;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-self:baseline;
}
.littlecastle{
    width: 15vw;
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    height:fit-content;
}
.buildcastle{
    background-color: #f8f2ee;
    align-items: center;
    display:flex;
    width: 80vw;
    height:fit-content;
    flex-direction:column;
    margin-left: 20vw;
}

.welcome {
    font-weight: 100vw;
    margin-top: 2vh;
    font-size: 4vw;
}

.hometext {
    font-size: 2vw;
}

/* button {
    height:7vh;
    width:20vw;
    font-size: 1.4vw;
    background-color: #5b9cd6;
    border-radius: 15px;
} */
.wellnessButtons {
    height:7vh;
    width:20vw;
    font-size: 1.4vw;
    background-color: #5b9cd6;
    border-radius: 15px;
}
.outermostrow{
    background-color: #f8f2ee;
    flex-direction: row;
    display: flex;
}
}
@media (max-width: 600px) {
    .outermostrow{
        background-color: #f8f2ee;
        width: 100vw;
        height: 85vh;
        flex-direction: row;
        justify-content: center;
    }
    .castlebigger{
        margin-top: 1vh;
        /* width: fit-content; */
        height:45vh;
        border: 3px solid black;
    
    }
    .castle{
        margin-top: 1vh;
        /* width: fit-content; */
        height:45vh;
        border: 3px solid black;
    }
    .littlecastlerow{
        margin-top: 1vh;
        margin-left: 5vw;
        margin-right: 4vw;
        margin-bottom: 8vh;
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        align-self:baseline;
        height: 0vh;

    }
    .littlecastle{
        width: 15vw;
        margin-top: 2vh;
        margin-left: 1vw;
        margin-right: 1vw;
        height:fit-content;
        height:0vh;
    }
    .buildcastle{
        background-color: #f8f2ee;
        align-items: center;
        display:flex;
        width: 80vw;
        height:fit-content;
        flex-direction:column;
    }
    .welcome{
        font-weight: 100vw;
        margin-top: 4vh;
        font-size: 8vw;
    }
    .hometext{
        margin-top: 2vh;
        font-size: 2.8vw;
    }
    /* button{
        margin-top: 2vh;
        height:5vh;
        width:25vw;
        font-size: 2.2vw;
        color: black;
        background-color: #5b9cd6;
        border: 1px solid black;
        border-radius: 15px;
    } */
    .wellnessButtons {
        margin-top: 2vh;
        height:5vh;
        width:25vw;
        font-size: 2.2vw;
        color: black;
        background-color: #5b9cd6;
        border: 1px solid black;
        border-radius: 15px;
    }
    .outermostrow{
        background-color: #f8f2ee;
        flex-direction: row;
        display: flex;
    }
}